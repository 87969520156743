<template>
  <shipblu-prompt
    class="order-modal stock-request"
    @close="closeModal"
    @cancel="closeModal"
    :active.sync="UROModal"
    :title="$t('Add URO')"
    :buttons-hidden="true" autocomplete="nofill">
    <div>
      <template v-if="!UROstock">
        <div class="clearfix flex justify-center">
          <div v-for="(type, index) in types" :key="index">
            <label @click="checkedItem(type)" class="prompt cursor-pointer whitespace-nowrap">
              <input v-model="selectedType" :class="type.checked ? 'checked' : ''" :value="type.value" type="radio" />
              <span class="py-2 px-4 rounded mx-1">{{ type.name }}</span>
            </label>
          </div>
        </div>
        <div class="max-w-sm m-auto my-4" v-if="selectedType === 'merchant'">
          <v-select @search="handleMerchantsSearch" @keyup.enter.native="handleMerchantsSearch" search autocomplete="nofill"
            name="merchant" v-model="merchant" :placeholder="$t('Merchant')" label="name" :options="merchants"/>
        </div>
        <div class="max-w-sm m-auto my-4" v-if="selectedType === 'stock-request'">
          <v-select @search="handleStockSearch" @keyup.enter.native="handleStockSearch" search autocomplete="nofill"
            name="stock request" v-model="stockRequest" :placeholder="$t('Stock Request')" label="label" :options="stockRequests"/>
        </div>
        <div v-show="!selectedFlag && (merchant === '' || stockRequest === '')" class="text-center">
          <span class="text-danger text-sm"> {{ selectedType === 'merchant' ? $t('You have to choose the merchant first') : $t('You have to choose the stock request first')}} </span>
        </div>
      </template>
      <vx-card v-for="(item, index) in unidentifiedProducts" :key="index" class="mt-4">
        <div class="items-end grid md:grid-cols-3 grid-cols-2 gap-x-4">
          <div v-if="item.type === 'unidentified'" class="md:col-span-1 col-span-2 md:mt-0 mt-4">
            <vs-input v-validate="'required'" :label-placeholder="$t('SKU Code') + '*'" :name="`sku code-${index}`" v-model="item.sku" :class="item.sku ? 'input-customer-info-filled' : ''" class="w-full input-customer-info"/>
            <span class="text-danger text-sm" v-show="errors.has(`sku code-${index}`)">{{ errors.first(`sku code-${index}`) ? errors.first(`sku code-${index}`).split(`-${index}`)[0] + errors.first(`sku code-${index}`).split(`-${index}`)[1]  : '' }}</span>
          </div>
          <div v-else class="md:col-span-1 col-span-2 md:mt-0 mt-4">
            <shipblu-v-select :class="item.product ? 'input-customer-info-filled' : ''" class="w-full input-customer-info product-dropdown" 
              autocomplete="nofill" optionalLabel="optionalLabel" label="sku" :name="`product-${index}`" v-model="item.product" v-validate="'required'" 
              :placeholder="$t('Product') + '*'" :options="productCatalog" @search="handleProductSearch" @keyup.enter.native="handleProductSearch" search/>
            <span class="text-danger text-sm" v-show="errors.has(`product-${index}`)">{{ errors.first(`product-${index}`) ? errors.first(`product-${index}`).split(`-${index}`)[0] + errors.first(`product-${index}`).split(`-${index}`)[1]  : '' }}</span>
          </div>
          <div class="md:col-span-1 col-span-2 md:mt-0 mt-4">
            <v-select :class="item.reason ? 'input-customer-info-filled remove-search' : ''" class="w-full input-customer-info product-dropdown" 
              autocomplete="nofill" label="name" :name="`reason-${index}`" v-model="item.reason" v-validate="'required'" 
              :placeholder="$t('Reason') + '*'" :options="item.type === 'unidentified' ? unidentifiedReasones : identifiedReasones"/>
            <span class="text-danger text-sm" v-show="errors.has(`reason-${index}`)">{{ errors.first(`reason-${index}`) ? errors.first(`reason-${index}`).split(`-${index}`)[0] + errors.first(`reason-${index}`).split(`-${index}`)[1]  : '' }}</span>
          </div>
          <div class="md:col-span-1 col-span-3 md:mt-0 mt-4">
            <div class="md:block flex items-center">
              <p class="text-sm text-lightgray mr-1">{{ $t('Incoming Quantity') }}:</p>
              <div>
                <vs-input-number min="1" :max="item.incoming_quantity" v-validate="'required|numeric'" name="quantity" v-model.number="item.quantity" :value="0" class="stock-request-input-number flex justify-start"/>
                <span class="text-danger text-sm" v-show="errors.has('quantity')">{{ errors.first('quantity') }}</span>
              </div>
            </div>
          </div>
          <div class="col-span-3">
            <vs-input :label-placeholder="$t('Product Description')" v-model="item.description" :class="item.description ? 'input-customer-info-filled' : ''" class="w-full input-customer-info"/>
          </div>
        </div>
        <div v-if="unidentifiedProducts.length > 1" @click="removeProduct(index)" class="remove-package-icon remove-icon-position cursor-pointer mb-1">
          <feather-icon icon="XIcon" svgClasses="w-3 h-3"/>
        </div>
      </vx-card>
      <template v-if="$route.name !== `${$store.state.AppActiveUser.userRole}-stock-request-view`">
        <div class="relative flex items-center justify-end mt-8">
          <div class="absolute add-products">
            <span class="text-sm font-medium py-1 px-2 rounded tooltip bg-white text-danger">{{$t('Add unidentified products')}}</span>
          </div>
          <div class="p-1 flex self-center bg-primary rounded">
            <feather-icon @click="addProduct('unidentified')" class="cursor-pointer" icon="PlusIcon" svgClasses="w-5 h-5 text-white" />
          </div>
        </div>
        <div class="relative flex items-center justify-end mt-4">
          <div class="absolute add-products">
            <span class="text-sm font-medium py-1 px-2 rounded tooltip bg-white text-primary">{{$t('Add identified products')}}</span>
          </div>
          <div class="p-1 flex self-center bg-primary rounded">
            <feather-icon @click="addProduct('identified')" class="cursor-pointer" icon="PlusIcon" svgClasses="w-5 h-5 text-white" />
          </div>
        </div>
      </template>
    </div>
    <div class="grid grid-cols-2 gap-4 mt-10">
      <button @click="closeModal" class="btn disable-btn">{{ $t('Cancel') }}</button>
      <button :disabled="unidentifiedProducts.length === 0" @click="addURO" :class="unidentifiedProducts.length === 0 ? 'disable-btn' : 'active-btn'" class="btn">{{ $t('Add') }}</button>
    </div>
  </shipblu-prompt>
</template>

<script>
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'
import vSelect from 'vue-select'
import { sendFulfillmentRequest } from '../../../http/axios/requestHelper'
import common from '../../../assets/utils/common'
import ShipbluVSelect from 'shipblu-vue-select'
import i18nData from '../../../i18n/i18nData'

export default {
  props: ['UROModal', 'UROstock', 'selected'],
  data () {
    return {
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      merchants: [],
      productCatalog: [],
      unidentifiedProducts: [],
      types: [
        {
          name: 'Merchant',
          value: 'merchant',
          checked: false
        },
        {
          name: 'Stock Request',
          value: 'stock-request',
          checked: false
        },
        {
          name: 'Other',
          value: 'other',
          checked: true
        }
      ],
      unidentifiedReasones: [
        {
          name: 'Product is not found in your catalog',
          value: 'product_not_found'
        }
      ],
      identifiedReasones: [
        {
          name: 'Product description is not matched',
          value: 'product_not_matched'
        },
        {
          name: 'Product was not added to the WRO',
          value: 'product_not_added_to_wro'
        }
      ],
      selectedFlag: true,
      selectedType: 'other',
      merchant: '',
      searchVal: '',
      stockRequests: [],
      stockRequest: '',
      searchInProgress: false,
      searchedValue: ' ',
      searchProductVal: '',
      searchedProductValue: ' ',
      selectedURO: [],
      reasonsDic: {'product_not_found': 'Product is not found in your catalog', 'product_not_matched': 'Product description is not matched', 'product_not_added_to_wro': 'Product was not added to the WRO'}
    }
  },
  watch: {
    types: {
      handler () {
        if (this.UROModal === true) {
          this.selectedFlag = true
        }
      },
      deep: true
    },
    merchant (val) {
      if (val) {
        this.unidentifiedProducts = []
        this.selectedFlag = true
        this.loadProductCatalog()
      } else {
        this.unidentifiedProducts = []
      }
    },
    stockRequest (val) {
      if (!this.selected) {
        if (val) {
          this.unidentifiedProducts = []
          this.selectedFlag = true
          this.loadProductCatalog()
        } else {
          this.unidentifiedProducts = []
        }
      }
    },
    UROModal (val) {
      if (val === true) {
        this.selectedURO.forEach(item => {
          this.unidentifiedProducts.push(
            item.product.sku,
            item.product.description,
            item.incoming_quantity
          )
        })
        if (this.UROstock) {
          this.checkedItem({
            name: 'Stock Request',
            value: 'stock-request',
            checked: false
          })
          this.unidentifiedProducts = []
          this.stockRequest = this.UROstock
        } else {
          this.loadProductCatalog()
        }
        if (this.selected) {
          this.stockRequest = this.UROstock
          this.selected.forEach(item => {
            this.unidentifiedProducts.push({
              product: item.product,
              type: 'identified',
              quantity: item.incoming_quantity
            })
          })
        }
      }
    },
    'searchProductVal' (val) {
      if (val === '') {
        this.loadProductCatalog()
      }
    },
    selectedType (val) {
      this.merchant = ''
      this.stockRequest = ''
      if (val === 'stock-request') {
        this.loadStockRequests()
      }
    }
  },
  methods: {
    removeProduct (index) {
      this.unidentifiedProducts.splice(index, 1)
    },
    addProduct (productType) {
      if (this.selectedType !== 'other' && ((this.merchant === null || this.merchant === '') && (this.stockRequest === null || this.stockRequest === ''))) {
        this.selectedFlag = false
      } else {
        this.unidentifiedProducts.push({
          sku: '',
          description: '',
          quantity: 1,
          product: '',
          type: productType
        })
      }
    },
    handleMerchantsSearch (search) {
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadMerchantSearch)
    },
    loadMerchantSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      if (this.searchVal) {
        this.loadMerchants()
      }
    },
    handleStockSearch (search) {
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadStockSearch)
    },
    loadStockSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      if (this.searchVal) {
        this.loadStockRequests()
      }
    },
    handleProductSearch (search) {
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadProductsSearch, 1000)
    },
    loadProductsSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      if (this.searchVal) {
        this.loadProductCatalog()
      }
    },
    loadProductCatalog () {
      const query = this.selectedType === 'merchant' ? `?merchant=${this.merchant.id}&limit=100&search=${this.searchProductVal}` :  (this.selectedType === 'stock-request' || this.$route.name.includes('fulfillment-stock-requests')) ? `?merchant=${this.stockRequest.merchant.id}&limit=100&search=${this.searchProductVal}` : `?limit=100&search=${this.searchProductVal}`
      this.searchInProgress = true
      sendFulfillmentRequest(false, false, this, `api/v1/fc/products/${query}`, 'get', null, true,
        (response) => {
          this.productCatalog = response.data.results
          this.productCatalog.map(item => { item.optionalLabel = `SKU: ${item.sku} . ${item.current_inventory_level} available` })
          this.searchedProductValue = this.searchProductVal
        }
      )
      this.searchInProgress = false
    },
    closeModal () {
      this.selectedFlag = true
      this.unidentifiedProducts = []
      this.checkedItem({
        name: 'Other',
        value: 'other',
        checked: false
      })
      this.selectedType = 'other'
      this.merchant = ''
      this.searchVal = ''
      this.stockRequest = ''
      this.$emit('UROModal', false)
    },
    checkedItem (type) {
      this.types.forEach(item => {
        if (item.name === type.name) {
          item.checked = true
        } else {
          item.checked = false
        }
      })
    },
    loadMerchants () {
      this.searchInProgress = true
      const query = `?limit=${this.maximumItems}&name=${this.searchVal}`
      sendFulfillmentRequest(false, false, this, `api/v1/fc/merchants/${query}`, 'get', null, true,
        (response) => {
          this.merchants = response.data.results
          this.searchedValue = this.searchVal
        }
      )
      this.searchInProgress = false
    },
    loadStockRequests () {
      this.searchInProgress = true
      const query = `?limit=${this.maximumItems}&name=${this.searchVal}`
      sendFulfillmentRequest(false, false, this, `api/v1/fc/stock-requests/${query}`, 'get', null, true,
        (response) => {
          this.stockRequests = response.data.results
          this.stockRequests.map(item => { item.label = `${item.id} from ${item.merchant.name}` })
          this.searchedValue = this.searchVal
        }
      )
      this.searchInProgress = false
    },
    addURO () {
      this.$validator.validateAll().then(result => {
        if ((result && this.selectedType === 'other') || (result && this.selectedType !== 'other' && (this.merchant !== '' || this.stockRequest !== ''))) {
          const URO = {
            unidentified_products: []
          }
          this.unidentifiedProducts.forEach(item => {
            URO.unidentified_products.push({
              product: item.product ? item.product.id : null,
              incoming_quantity: item.quantity,
              description: item.description ? item.description : '',
              sku: item.sku ? item.sku : item.product.sku,
              reason: item.reason ? item.reason.value : ''
            })
          })
          if (this.selectedType === 'merchant') {
            URO.merchant = this.merchant.id
          } else if (this.selectedType === 'stock-request' || this.$route.name.includes('fulfillment-stock-requests') || this.$route.name.includes('stock-request-view')) {
            URO.stock_request = this.stockRequest.id
          }
          sendFulfillmentRequest(false, false, this, 'api/v1/fc/unidentified-stock-requests/', 'post', URO, true,
            () => {
              this.$vs.notify({
                color:'success',
                title:i18nData[this.$i18n.locale]['Success'],
                text:i18nData[this.$i18n.locale]['URO'] + i18nData[this.$i18n.locale][' has been created successfully!'],
                position: 'top-center'
              })
              this.closeModal()
              this.$emit('loadUROs')
            }
          )
        }
      })
    }
  },
  components: {
    ShipbluPrompt,
    vSelect,
    ShipbluVSelect
  },
  created () {
    this.loadMerchants()
  }
}
</script>

<style lang="scss">
.remove-package-icon {
  background: rgba(157, 173, 194, 0.32);
  border-radius: 3px;
  padding: 0px 4px;
  &:hover {
    background: #9DADC2;
    color: #fff;
  }
}
</style>