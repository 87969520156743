<template>
  <shipblu-prompt
    class="order-modal stock-request"
    @close="closeModal"
    @cancel="closeModal"
    :active.sync="addUROModal"
    :title="$t('Add URO')"
    :buttons-hidden="true" autocomplete="nofill">
    <div>
      <vx-card class="mt-4">
        <div class="items-end grid md:grid-cols-3 grid-cols-2 gap-x-4">
          <div class="md:col-span-1 col-span-2 md:mt-0 mt-4">
            <shipblu-v-select disabled :class="qcProductData.product.name ? 'input-customer-info-filled' : ''" class="w-full input-customer-info product-dropdown" 
              autocomplete="nofill" optionalLabel="optionalLabel" label="sku" name= "product" v-model="qcProductData.product.name" v-validate="'required'" 
              :placeholder="$t('Product') + '*'" :options="productCatalog"/>
            <span class="text-danger text-sm" v-show="errors.has(`product`)">{{ errors.first(`product`)}}</span>
          </div>
          <div class="md:col-span-1 col-span-2 md:mt-0 mt-4">
            <v-select :class="qcProductData.reason ? 'input-customer-info-filled' : ''" class="w-full input-customer-info product-dropdown" 
              autocomplete="nofill" label="name" name="reason" v-model="qcProductData.reason" v-validate="'required'" 
              :placeholder="$t('Reason') + '*'" :options="identifiedReasones"/>
            <span class="text-danger text-sm" v-show="errors.has(`reason`)">{{ errors.first(`reason`)}}</span>
          </div>
          <div class="md:col-span-1 col-span-3 md:mt-0 mt-4">
            <div class="md:block flex items-center">
              <p class="text-sm text-lightgray mr-1">{{ $t('Incoming Quantity') }}:</p>
              <div>
                <vs-input-number min="1" :max="qcProductData.incoming_quantity" v-validate="'required|numeric'" name="quantity" v-model.number="qcProductData.incoming_quantity" :value="0" class="stock-request-input-number flex justify-start"/>
                <span class="text-danger text-sm" v-show="errors.has('quantity')">{{ errors.first('quantity') }}</span>
              </div>
            </div>
          </div>
          <div class="col-span-3">
            <vs-input :label-placeholder="$t('Product Description')" v-model="qcProductData.description" :class="qcProductData.description ? 'input-customer-info-filled' : ''" class="w-full input-customer-info"/>
          </div>
        </div>
      </vx-card>
    </div>
    <div class="grid grid-cols-2 gap-4 mt-10">
      <button @click="closeModal" class="btn disable-btn">{{ $t('Cancel') }}</button>
      <button @click="addURO(qcProductData)" class="btn active-btn">{{ $t('Add') }}</button>
    </div>
  </shipblu-prompt>
</template>

<script>
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'
import vSelect from 'vue-select'
import { sendFulfillmentRequest } from '../../../http/axios/requestHelper'
import ShipbluVSelect from 'shipblu-vue-select'

export default {
  props: ['qcProductData', 'addUROModal'],
  data () {
    return {
      productCatalog: [],
      identifiedReasones: [
        {
          name: 'Product description is not matched',
          value: 'product_not_matched'
        },
        {
          name: 'Product was not added to the WRO',
          value: 'product_not_added_to_wro'
        }
      ]
    }
  },
  methods: {
    closeModal () {
      this.$emit('addUROModal', false)
    },
    addURO (data) {
      this.$validator.validateAll().then(result => {
        if (result) {
          const URO = {
            unidentified_products: [],
            stock_request : this.$route.params.stockID
          }
          URO.unidentified_products.push({
            product: data.product ? data.product.id : null,
            incoming_quantity: data.incoming_quantity,
            description: data.description ? data.description : '',
            sku: data.sku ? data.sku : data.product.sku,
            reason: 'product_not_matched'
          })
          sendFulfillmentRequest(false, false, this, 'api/v1/fc/unidentified-stock-requests/', 'post', URO, true,
            () => {
              this.closeModal()
              this.$emit('loadUROs')
              this.$emit('confirmQC', this.qcProductData, 'failed')
            }
          )
        }
      })
    }
  },
  components: {
    ShipbluPrompt,
    vSelect,
    ShipbluVSelect
  }
}
</script>

<style lang="scss">
.remove-package-icon {
  background: rgba(157, 173, 194, 0.32);
  border-radius: 3px;
  padding: 0px 4px;
  &:hover {
    background: #9DADC2;
    color: #fff;
  }
}
</style>