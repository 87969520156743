<template>
  <shipblu-prompt
    class="shipment-modal stock-request"
    @close="closeModal"
    @cancel="closeModal"
    :active.sync="stockProductModal"
    :title="$t('Edit Product')"
    :buttons-hidden="true" autocomplete="nofill">
    <div>
      <div class="items-end grid md:grid-cols-7 sm:grid-cols-5 grid-cols-1 md:gap-4 gap-2 mt-4">
        <div class="sm:col-span-2 col-span-1">
          <v-select disabled class="input-customer-info-filled remove-search w-full input-customer-info product-dropdown" 
            autocomplete="nofill" label="name" :name="'product'" v-model="stockProduct.product" v-validate="'required'" 
            :placeholder="$t('Product') + '*'" :options="productCatalog"/>
            <span class="text-danger text-sm" v-show="errors.has('product')">{{ errors.first('product') }}</span>
        </div>
        <div class="sm:col-span-3 col-span-1">
          <v-select :disabled="$store.state.AppActiveUser.userRole !== 'fc-operations-supervisor' || (stockRequest.status !== 'submitted' && stockRequest.status !== 'ready')" :class="stockProduct.flags && stockProduct.flags.length !== 0 ? 'input-customer-info-filled' : ''" class="w-full input-customer-info" 
            multiple autocomplete="nofill" label="name" :name="'flags'" v-model="stockProduct.flags" :placeholder="$t('Flags')" :options="productFlags"/>
        </div>
        <div class="md:col-span-2 col-span-5 flex items-center w-full justify-between">
          <div class="md:block flex items-center">
            <p class="text-sm text-lightgray mr-1">{{ $t('Received Quantity') }}:</p>
            <div>
              <vs-input-number min="0" v-validate="'required|numeric'" name="quantity" v-model="stockProduct.received_quantity" :value="0" class="stock-request-input-number flex justify-start"/>
              <span class="text-danger text-sm" v-show="errors.has('quantity')">{{ errors.first('quantity') }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-2 gap-4 mt-10">
      <button @click="closeModal" class="btn disable-btn">{{ $t('Cancel') }}</button>
      <button @click="updateStockProduct" class="active-btn btn">{{ $t('Save') }}</button>
    </div>
  </shipblu-prompt>
</template>

<script>
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'
import {sendFulfillmentRequest} from '../../../http/axios/requestHelper.js'
import i18nData from '../../../i18n/i18nData.js'
import vSelect from 'vue-select'

export default {
  props: ['stockProductModal', 'type', 'stockProduct', 'stockRequest'],
  data () {
    return {
      productCatalog: [],
      productFlags: []
    }
  },
  components: {
    ShipbluPrompt,
    vSelect
  },
  methods: {
    closeModal () {
      this.$emit('stockProductModal', false)
      this.$emit('loadStockProducts')
    },
    loadProductFlags () {
      sendFulfillmentRequest(false, false, this, 'api/v1/fc/flags/', 'get', null, true,
        (response) => {
          this.productFlags = response.data.results
        }
      )
    },
    updateStockProduct () {
      this.$validator.validateAll().then(result => {
        if (result) {
          const stockProductData = {
            flags: this.stockProduct.flags.map(item => item.id),
            product: this.stockProduct.product.id,
            received_quantity: Number(this.stockProduct.received_quantity)
          }
          sendFulfillmentRequest(false, false, this, `api/v1/fc/stock-requests/${this.$route.params.stockID}/products/${this.stockProduct.id}/`, 'patch', stockProductData, true,
            () => {
              this.closeModal()
              this.$vs.notify({
                color:'success',
                title:i18nData[this.$i18n.locale]['Success'],
                text:i18nData[this.$i18n.locale]['Product'] + i18nData[this.$i18n.locale][' has been created successfully!'],
                position: 'top-center'
              })
            }
          )
        }
      }) 
    }
  },
  created () {
    this.loadProductFlags()
  }
}
</script>

<style lang="scss">
.add-products {
  right: 33px;
  .tooltip {
    box-shadow: 0px 0px 6px rgba(108, 132, 163, 0.6);
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      margin-top: -7px;
      border-width: 7px;
      border-style: solid;
      border-color: transparent #1C5BFE transparent transparent;
    }
  }
}
.remove-package-icon {
  background: rgba(157, 173, 194, 0.32);
  border-radius: 3px;
  padding: 0px 4px;
  &:hover {
    background: #9DADC2;
    color: #fff;
  }
}
div.stock-request {
  .vs-input-number {
    * {
      padding: 0px !important;
    }
  }
  .vs-input-number--input {
    font-size: 15px !important;
  }
  .vs-input-number {
    margin: 0px 10px!important;
  }
  .vs-dialog{
    max-width: 800px !important;
  }
}
div.stock-request-input-number {
  button {
    width: 15px !important;
    height: 15px !important;
    
  }
}
</style>