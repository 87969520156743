<template>
  <shipblu-prompt
    class="shipment-modal stock-request"
    @close="closeModal"
    @cancel="closeModal"
    :active.sync="inboundProductModal"
    :title="$t('Inbound Product')"
    :buttons-hidden="true" autocomplete="nofill">
    <div class="items-end grid md:grid-cols-7 sm:grid-cols-5 grid-cols-1 md:gap-4 gap-2 mt-4">
      <div class="sm:col-span-2 col-span-1">
        <v-select disabled class="input-customer-info-filled remove-search w-full input-customer-info product-dropdown" 
          autocomplete="nofill" label="sku" :name="'product'" v-model="stockProduct.product" v-validate="'required'" 
          :placeholder="$t('Product') + '*'" :options="productCatalog"/>
          <span class="text-danger text-sm" v-show="errors.has('product')">{{ errors.first('product') }}</span>
      </div>
      <div class="sm:col-span-3 col-span-1">
        <v-select disabled :class="stockProduct.flags && stockProduct.flags.length !== 0 ? 'input-customer-info-filled' : ''" class="w-full input-customer-info" 
          multiple autocomplete="nofill" label="name" :name="'flags'" v-model="stockProduct.flags" :placeholder="$t('Flags')" :options="productFlags"/>
      </div>
      <div class="md:col-span-2 col-span-5 flex items-center w-full justify-between">
        <div class="md:block flex items-center">
          <p class="text-sm text-lightgray mr-1">{{ $t('Inbounded Quantity') }}:</p>
          <div>
            <vs-input-number min="0" :max="stockProduct.received_quantity" v-validate="'required|numeric'" name="quantity" v-model="stockProduct.inbounded_quantity" class="stock-request-input-number flex justify-start"/>
            <span class="text-danger text-sm" v-show="errors.has('quantity')">{{ errors.first('quantity') }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="ml-4">
      <p class="mt-10 font-medium text-lg">{{$t('Storage Bins')}}</p>
      <div v-for="(item, index) in storageBins " :key="index">
        <div class="items-end grid md:grid-cols-7 sm:grid-cols-5 grid-cols-1 md:gap-4 gap-2 mt-4 mb-6">
          <div class="sm:col-span-2 col-span-1">
            <v-select :class="item.bin ? 'input-customer-info-filled remove-search' : ''" class="w-full input-customer-info product-dropdown" 
              autocomplete="nofill" label="name" name="bin" v-model="item.bin" v-validate="'required'" 
              :placeholder="$t('Bins') + '*'" :options="availableBins"/>
            <span class="text-danger text-sm" v-show="errors.has('bin')">{{ errors.first('bin') }}</span>
          </div>
          <div class="md:col-span-2 col-span-5 flex items-center w-full justify-between">
            <div class="md:block flex items-center">
              <p class="text-xs text-lightgray mr-1">{{ $t('Inbounded Quantity') }}:</p>
              <div>
                <vs-input-number min="0" :class="(stockProduct.inbounded_quantity - totalInboundedQuantity === 0) ? 'input-number-disabled' : ''" :max="stockProduct.inbounded_quantity" v-validate="'required|numeric'" name="quantity" v-model="item.inbounded_quantity" :value="item.received_quantity" class="stock-request-input-number flex justify-start"/>
                <span class="text-danger text-sm" v-show="errors.has('quantity')">{{ errors.first('quantity') }}</span>
              </div>
            </div>
            <span v-if="storageBins.length > 1" @click="removeBin(index)" class="remove-package-icon cursor-pointer mb-1">
              <feather-icon icon="XIcon" svgClasses="w-3 h-3"/>
            </span>
          </div>
        </div>
      </div>
      <div v-if="addMoreBins" class="relative flex items-center justify-end mt-8">
        <div class="absolute add-products">
          <span class="text-sm font-medium py-1 px-2 rounded tooltip bg-white text-primary">{{$t('Add more Bins')}}</span>
        </div>
        <div class="p-1 flex self-center bg-primary rounded">
          <feather-icon @click="addBin()" class="cursor-pointer" icon="PlusIcon" svgClasses="w-5 h-5 text-white" />
        </div>
      </div>
    </div>
    <div class="grid grid-cols-2 gap-4 mt-10">
      <button @click="closeModal" class="btn disable-btn">{{ $t('Cancel') }}</button>
      <button :disabled="addMoreBins" :class="addMoreBins ? 'disable-btn' : 'active-btn'" @click="updateInboundedProduct" class="btn">{{ $t('Save') }}</button>
    </div>
  </shipblu-prompt>
</template>

<script>
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'
import {sendFulfillmentRequest} from '../../../http/axios/requestHelper.js'
import i18nData from '../../../i18n/i18nData.js'
import vSelect from 'vue-select'

export default {
  props: ['inboundProductModal', 'stockProduct'],
  data () {
    return {
      productCatalog: [],
      productFlags: [],
      bins: [],
      storageBins: [
        {
          bin: '',
          inbounded_quantity: this.stockProduct.received_quantity
        }
      ],
      totalInboundedQuantity: 0,
      addMoreBins: true,
      availableBins: [],
      stockProductData: {}
    }
  },
  watch: {
    'stockProduct.inbounded_quantity' (val) {
      this.storageBins = []
      this.storageBins.push(
        {
          bin: '',
          inbounded_quantity: this.stockProduct.received_quantity
        }
      )
      this.storageBins[0].inbounded_quantity = val
    },
    'storageBins': {
      handler (val) {
        this.totalInboundedQuantity = 0
        val.forEach(item => {
          this.totalInboundedQuantity += Number(item.inbounded_quantity)
        })
        if (this.totalInboundedQuantity >= this.stockProduct.inbounded_quantity) {
          this.addMoreBins = false
        } else {
          this.addMoreBins = true
        }
        const selectedBins = []
        this.availableBins = []
        this.bins.forEach(element => {
          this.availableBins.push(element)
        })
        if (val) {
          val.forEach(item => {
            if (item.bin) {
              selectedBins.push(item.bin)
            }
          })
        }
        for (let i = 0; i < selectedBins.length; i++) {
          this.availableBins.forEach(element => {
            if (element.id === selectedBins[i].id) {
              this.availableBins.splice(this.availableBins.indexOf(element), 1)
            }
          })
        }
      },
      deep: true
    }
  },
  components: {
    ShipbluPrompt,
    vSelect
  },
  methods: {
    removeBin (index) {
      this.storageBins.splice(index, 1)
    },
    addBin () {
      this.storageBins.push({
        bin: '',
        inbounded_quantity: this.stockProduct.inbounded_quantity - this.totalInboundedQuantity
      })
    },
    loadBins () {
      sendFulfillmentRequest(false, false, this, 'api/v1/fc/bins/?limit=100&statuses=created,stored', 'get', null, true,
        (response) => {
          this.bins = response.data.results
          this.searchedValue = this.searchVal
        }
      )
    },
    closeModal () {
      this.$emit('inboundProductModal', false)
      this.$emit('loadStockProducts')
    },
    loadProductFlags () {
      sendFulfillmentRequest(false, false, this, 'api/v1/fc/flags/', 'get', null, true,
        (response) => {
          this.productFlags = response.data.results
        }
      )
    },
    updateInboundedProduct () {
      this.$validator.validateAll().then(result => {
        if (result) {
          const binsInStorage = []
          this.storageBins.forEach(item => {
            binsInStorage.push({
              bin: item.bin.id,
              product_in_stock_request: this.stockProduct.id,
              flags: this.stockProduct.flags ? this.stockProduct.flags.map(item => item.id) : [],
              inbounding_quantity: Number(item.inbounded_quantity)
            })
          })
          sendFulfillmentRequest(false, false, this, 'api/v1/fc/products-in-bin/', 'post', binsInStorage, true,
            () => {
              this.updateInboundedQuantity()
              this.$vs.notify({
                color:'success',
                title:i18nData[this.$i18n.locale]['Success'],
                text:i18nData[this.$i18n.locale]['Product'] + i18nData[this.$i18n.locale][' has been updated successfully!'],
                position: 'top-center'
              })
            }
          )
        }
      })
    },
    updateInboundedQuantity () {
      let inboundQuantity = 0
      this.storageBins.forEach(item => {
        inboundQuantity += Number(item.inbounded_quantity)
      })
      sendFulfillmentRequest(false, false, this, `api/v1/fc/stock-requests/${this.$route.params.stockID}/products/${this.stockProduct.id}/`, 'patch', {inbounded_quantity: inboundQuantity}, true,
        () => {
          this.closeModal()
        }
      )
    }
  },
  created () {
    this.loadProductFlags()
    this.loadBins()
  }
}
</script>

<style lang="scss">
.add-products {
  right: 33px;
  .tooltip {
    box-shadow: 0px 0px 6px rgba(108, 132, 163, 0.6);
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      margin-top: -7px;
      border-width: 7px;
      border-style: solid;
      border-color: transparent #1C5BFE transparent transparent;
    }
  }
}
.remove-package-icon {
  background: rgba(157, 173, 194, 0.32);
  border-radius: 3px;
  padding: 0px 4px;
  &:hover {
    background: #9DADC2;
    color: #fff;
  }
}
div.stock-request {
  .vs-input-number {
    * {
      padding: 0px !important;
    }
  }
  .vs-input-number--input {
    font-size: 15px !important;
  }
  .vs-input-number {
    margin: 0px 10px!important;
  }
  .vs-dialog{
    max-width: 800px !important;
  }
}
div.stock-request-input-number {
  button {
    width: 15px !important;
    height: 15px !important;
    
  }
}
.input-number-disabled {
  button.btn-plus {
    pointer-events: none;
    background: rgba(0,0,0,.5);
    opacity: 0.5;
  }
}
</style>