<template>
  <div class="my-2">
    <template v-if="selectedFlag">
      <div class="cursor-pointer">
        <div class="available-action flex px-4 py-2" @click="openUroModal()">
          <feather-icon icon="XCircleIcon" svgClasses="w-4 h-4" class="self-center"/>
          <span class="text-sm ml-2">{{ $t('Create URO') }}</span>
        </div>
      </div>
    </template>
    <template v-else>
      <vx-tooltip position="bottom" color="warning" :text="`${$t('Please select only the product you want to create URO for')}`">
        <div class="cursor-not-allowed">
          <div class="pointer-events-none unavailable-action flex px-4 py-2">
            <feather-icon icon="XCircleIcon" svgClasses="w-4 h-4" class="self-center"/>
            <span class="text-sm ml-2">{{ $t('Create URO') }}</span>
          </div>
        </div>
      </vx-tooltip>
    </template>
    <add-unidentified-stock :UROstock="UROstock" :UROModal="UROModal" @UROModal="UROModal=$event" :selected="selected"/>
  </div>
</template>

<script>
import AddUnidentifiedStock from '../components/AddUnidentifiedStock.vue'

export default {
  props: ['selected', 'UROstock'],
  components: {
    AddUnidentifiedStock
  },
  data () {
    return {
      selectedFlag: false,
      UROModal: false
    }
  },
  watch: {
    selected (val) {
      if (val.length !== 0) {
        this.selectedFlag = true
      } else {
        this.selectedFlag = false
      }
    }
  },
  methods: {
    openUroModal () {
      this.UROModal = true
    }
  }
}
</script> 